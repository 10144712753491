import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/fonts.css'
import Layout from "../layouts/Layout"
import ARMRVR from '../components/ARMRVR'
import About from "../components/About"
import Modeling3D from '../components/Modeling3D'
import Scaning from '../components/Scaning'
import LivePerformance from '../components/LivePerformance'
import EventsPhysical from '../components/EventsPhysical'
import EventsVirtual from '../components/EventsVirtual'
import Motion from '../components/Motion'
import Games from '../components/Games'
import Join from '../components/Join'
import Hero from '../components/3D/Hero'
import Footer from '../components/Common/Footer'
import Header from '../components/Common/Header'
import GoogleAnalytics from '../layouts/GoogleAnalytics'
import SEO from '../components/Common/SEO'

export default class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isTransparent: true,
            isLoaded: false
        }
        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    handleScroll = (event) => {
        if (typeof window !== 'undefined') {
            const node = this.myRef?.current;
            if (node && window && window.innerWidth > 768) {
                let isTransparent = node.scrollTop < window.innerHeight - 5;
                if ((isTransparent && !this.state.isTransparent) || (!isTransparent && this.state.isTransparent))
                    this.setState({
                        isTransparent: isTransparent,
                    });
            }
        }
    }

    render() {
        return (
            <div id="scrollable" onScroll={(e) => this.handleScroll(e)} ref={this.myRef}>
                <SEO 
                    title={'Verse Estate - Home page'}
                    description={'Welcome to the Verse Estate website'}
                />

                <Header isTransparent={this.state.isTransparent} isLoaded={this.state.isLoaded}/>
                <div className='snap'>
                    <Hero />
                </div>
                <div className='snap height-fixed-150'>
                    <Scaning />
                </div>
                <div className='snap end'>
                    <ARMRVR />
                </div>
                <div className='snap height-fixed-150'>
                    <Modeling3D />
                </div>
                <div className='snap'>
                    <Motion />
                </div>
                <div className='snap'>
                    <LivePerformance />
                </div>
                <div className='snap'>
                    <Games />
                </div>
                <div className='snap'>
                    <About />
                </div>
                <div className='snap'>
                    <Join />
                </div>
                <Footer />
            </div>
        )
    }
}
